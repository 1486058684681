<template>
    <div>
        <template v-if="link">
            <span><router-link :to="{ path: '/openvpn-config/view', query: { id, accountId } }">{{ label }}</router-link></span>
        </template>
        <template v-if="!link">
            <span>{{ label }}</span>
        </template>
        <p class="text-caption my-0 py-0">{{ dev }} {{ proto }} {{ digestAlg }} {{ cipherAlg }} {{ compression }}</p>
        <!-- <p class="text-caption my-0 py-0">{{ id }}</p> -->
    </div>
</template>

<script>
export default {

    props: {
        // whether the item should link to the openvpn config record; a parent component may set this to false if it just wants the user to select an openvpn config (and not navigate to it)
        link: {
            type: Boolean,
            default: false,
        },
        accountId: {
            type: String,
        },
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        label() {
            return this.attr.label;
        },
        dev() {
            return this.attr.info ? this.attr.info.dev : '';
        },
        proto() {
            return this.attr.info ? this.attr.info.proto : '';
        },
        digestAlg() {
            return this.attr.info ? this.attr.info.digestAlg : '';
        },
        cipherAlg() {
            return this.attr.info ? this.attr.info.cipherAlg : '';
        },
        compression() {
            return this.attr.info ? this.attr.info.compression : '';
        },
    },

};
</script>
