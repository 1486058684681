<template>
    <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="indigo--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="indigo" @click="displayCreateOpenvpnConfig = true" v-if="add || create">
                <font-awesome-icon icon="plus" style="font-size: 20px;" fixed-width/>
            </v-btn>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list === null">Loading data...</v-list-item>
            <v-list-item v-if="list !== null && list.length === 0">No OpenVPN configurations.</v-list-item>
            <template v-if="list !== null && list.length > 0">
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <v-list-item-content>
                    <OpenvpnConfigListItem :attr="item" :accountId="accountId" :link="!selectOne"></OpenvpnConfigListItem>
                </v-list-item-content>
            </v-list-item>
            </template>
        </v-list>
        <v-dialog v-model="displayCreateOpenvpnConfig" max-width="600">
            <v-card elevation="4">
                <v-toolbar short flat>
                    <v-toolbar-title>Create a new OpenVPN configuration</v-toolbar-title>
                </v-toolbar>
                <v-form v-model="createOpenvpnConfigForm" ref="createOpenvpnConfigFormRef" @submit="validateCreateOpenvpnConfig" onSubmit="return false;" @keyup.enter.native="validateCreateOpenvpnConfig" class="px-4 py-4">
                    <div v-if="add">
                        <v-select :items="addOpenvpnConfigChoices" v-model="addOpenvpnConfigId" label="Select an OpenVPN configuration"></v-select>
                    </div>
                    <div v-if="create">
                        <!--
                        <v-text-field
                            v-model=name
                            label="Name"
                            :rules="nameRules"
                            validate-on-blur
                            color="indigo"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="openvpnConfig" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>
                        -->
                        <v-text-field
                            ref="labelInputRef"
                            v-model=newOpenvpnConfigLabel
                            label="Label"
                            :rules="newOpenvpnConfigLabelRules"
                            validate-on-blur
                            color="indigo"
                            required
                            hint="The label for the OpenVPN configuration"
                            type="text"
                            outlined
                        >
                        </v-text-field>

                        <v-expansion-panels focusable>
                            <v-expansion-panel>
                                <v-expansion-panel-header>Advanced</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-2">We recommend using the default values for the best security and performance.</p>
                                    <v-select v-model="dev" :items="devChoices" label="Virtual device type"></v-select>
                                    <v-select v-model="proto" :items="protoChoices" label="Protocol"></v-select>
                                    <v-select v-model="digestAlg" :items="digestAlgChoices" label="Digest algorithm"></v-select>
                                    <v-select v-model="cipherAlg" :items="cipherAlgChoices" label="Cipher algorithm"></v-select>
                                    <v-select v-model="compression" :items="compressionChoices" label="Compression"></v-select>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                    </div>
                    <v-row justify="center" class="mt-2">
                        <v-card-actions>
                            <v-btn elevation="4" class="indigo white--text" @click="addOpenvpnConfig" :disabled="!createOpenvpnConfigForm" v-if="add">
                                <font-awesome-icon :icon="['fas', 'check']" fixed-width/>
                                <span class="ml-2">Add</span>
                            </v-btn>
                            <v-btn elevation="4" class="indigo white--text" @click="createOpenvpnConfig" :disabled="!createOpenvpnConfigForm" v-if="create">
                                <font-awesome-icon :icon="['fas', 'check']" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-form>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import OpenvpnConfigListItem from '@/components/OpenvpnConfigListItem.vue';
import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        OpenvpnConfigListItem,
    },

    props: {
        // optional; use this when you want to use the account list to select an account; emits 'selected' when user taps on an account
        selectOne: {
            type: Boolean,
            default: false,
        },
        // optional; true means the 'plus' button will be for adding a user to a list
        add: {
            type: Boolean,
            default: false,
        },
        // optional; true means the 'plus' button will be allowed to create new user records if they don't already exist
        create: {
            type: Boolean,
            default: false,
        },
        accountId: {
            type: String,
        },
        // policyId is optional; when provided, the create openvpnConfig dialog functions as an 'add or create' dialog and allows user to choose from an existing openvpnConfig to add to the list, or create a new openvpnConfig
        policyId: {
            type: String,
        },
        list: {
            type: Array,
        },
    },

    data() {
        return {
            // list: [],
            // create openvpnConfig
            displayCreateOpenvpnConfig: false,
            createOpenvpnConfigForm: null,
            newOpenvpnConfigLabel: null,
            newOpenvpnConfigLabelRules: [
                (v) => !!v || 'Label is required',
                (v) => !v || isValidName(compact(v)) || 'A label for this OpenVPN configuration is required',
            ],
            dev: 'tun',
            devChoices: [
                { text: 'Layer 2 bridge (TAP)', value: 'tap' },
                { text: 'Layer 3 tunnel (TUN)', value: 'tun' },
            ],
            proto: 'udp',
            protoChoices: [
                { text: 'TCP', value: 'tcp' },
                { text: 'UDP', value: 'udp' },
            ],
            digestAlg: 'SHA384',
            digestAlgChoices: [
                { text: 'SHA-256', value: 'SHA256' },
                { text: 'SHA-384', value: 'SHA384' },
            ],
            cipherAlg: 'AES-256-CBC',
            cipherAlgChoices: [
                { text: 'AES-256-CBC', value: 'AES-256-CBC' },
                { text: 'AES-256-GCM', value: 'AES-256-GCM' },
            ],
            compression: 'lz4-v2',
            compressionChoices: [
                { text: 'lz4-v2', value: 'lz4-v2' },
                { text: 'None', value: '' },
            ],
            // add openvpnConfig
            addOpenvpnConfigChoices: [],
            addOpenvpnConfigId: null,
        };
    },

    computed: {
        id() {
            return this.policyId;
        },
        label() {
            if (this.selectOne) {
                return 'Select an OpenVPN configuration';
            }
            if (this.list === null) {
                return 'OpenVPN Configurations';
            }
            switch (this.list.length) {
            case 0:
                return 'No OpenVPN configurations';
            case 1:
                return '1 OpenVPN configuration';
            default:
                return `${this.list.length} OpenVPN configurations`;
            }
        },
    },

    methods: {
        onClickItem(openvpnConfigId) {
            if (this.selectOne) {
                this.$emit('selected', { openvpnConfigId });
            }
        },
        async createOpenvpnConfig() {
            this.error = false;
            console.log('createOpenvpnConfig');
            const request = {
                accountId: this.accountId,
                label: this.newOpenvpnConfigLabel,
                dev: this.dev,
                proto: this.proto,
                digestAlg: this.digestAlg,
                cipherAlg: this.cipherAlg,
                compression: this.compression,
            };
            const response = await this.$client.openvpnConfig.create(request);
            console.log('createOpenvpnConfig response: %o', response);
            const { isCreated, id, error } = response;
            if (isCreated) {
                const openvpnConfig = { id, ...request };
                if (this.list === null) { this.list = []; }
                this.list.push(openvpnConfig);
                this.$emit('created-openvpnConfig', openvpnConfig);
                this.$emit('added-openvpnConfig', openvpnConfig);
                this.displayCreateOpenvpnConfig = false;
                this.newOpenvpnConfigLabel = null;
            } else if (error) {
                this.error = error;
            } else {
                this.error = 'Request failed';
            }
        },
        validateCreateOpenvpnConfig() {
            if (this.$refs.createOpenvpnConfigFormRef.validate()) {
                this.createOpenvpnConfig();
            }
        },

        async addOpenvpnConfig() {
            const openvpnConfig = { id: this.addOpenvpnConfigId, label: this.addOpenvpnConfigChoices.find((item) => item.value === this.addOpenvpnConfigId).text };
            if (this.list === null) { this.list = []; }
            this.list.push(openvpnConfig);
            this.$emit('added-openvpnConfig', openvpnConfig);
            this.displayCreateOpenvpnConfig = false;
            this.addOpenvpnConfigId = null;
        },
        // async initAddOpenvpnConfigChoices() {
        //     // create a map of which openvpnConfig ids are already in the list
        //     const openvpnConfigMap = {};
        //     this.list.forEach((item) => {
        //         openvpnConfigMap[item.id] = true;
        //     });
        //     // when we show the add/create openvpnConfig dialog, load the list of available openvpnConfigs to add to the policy (list of all openvpnConfigs less the ones already in the policy)
        //     const result = await this.$client.openvpnConfig.list();
        //     console.log(`OpenvpnConfigViewList.vue fetch openvpnConfigs result: ${JSON.stringify(result)}`);
        //     if (result && result.list) {
        //         // filter the results to remove openvpnConfigs already in the policy, then convert the available openvpnConfigs to a choice list for the v-select [ { text, value }, ... ]
        //         this.addOpenvpnConfigChoices = result.list.filter((item) => !openvpnConfigMap[item.id]).map((item) => ({ text: item.label, value: item.id }));
        //     }
        // },
    },

    // watch: {
    //     displayCreateOpenvpnConfig(value, oldValue) {
    //         if (this.policyId && value && !oldValue) {
    //             this.initAddOpenvpnConfigChoices();
    //         }
    //     },
    // },
};
</script>
